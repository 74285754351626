
import galleryImg01 from "../../assets/images/colombo.jpg"
import galleryImg02 from "../../assets/images/kandycity.jpg"
import galleryImg03 from "../../assets/images/turtle.jpg"
import galleryImg04 from "../../assets/images/yala 2.jpg"
import galleryImg05 from "../../assets/images/idalgashinna.jpg"
import galleryImg06 from "../../assets/images/nuwaea elo.jpg"
import galleryImg07 from "../../assets/images/kandy.jpg"
import galleryImg08 from "../../assets/images/ella (2).jpg"



const galleryImages=[
  galleryImg01,
  galleryImg04,
  galleryImg02,
  galleryImg03,
  galleryImg08,
  galleryImg07,
  galleryImg06,
  galleryImg05,
 
]

export default galleryImages